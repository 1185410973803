<template>
  <div>
    <b-container class="base-container-x about-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <br/>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mb-5">
        <div class="pt-5 d-block">
          <h1 class="section-header-title text-uppercase">
            {{ $t('Billing') }}
          </h1>
        </div>
        <div class="row mt-5">
          <b-col xl="6" lg="6" sm="12" md="12" class="mb-4">
            <b-card :title="$t('Outstanding balance')">
              <b-card-text>
                <p v-if="outStandingAmount.amount > 0">{{ $t('Waiting for payment') }} </p>
                {{ $t('This is current costs for your bill or orders amount .A breakdown of your costs is available below') }}
              </b-card-text>
              <div class="flex-justify-content-space-between">
                <h2 v-if="!isLoadingOutstanding">{{ outStandingAmount.amount | numeral('0,0.00') }}</h2>
                <b-spinner v-else class="text-primary-dark" label="Loading"></b-spinner>
                <div class="columns">
                   <b-dropdown size="" :text="selectedOutStandingCurrency" variant="primary">
                    <b-dropdown-item-button @click="selectOutStandingCurrency('thb')" class="d-none">THB - Thai Baht</b-dropdown-item-button>
                    <b-dropdown-item-button @click="selectOutStandingCurrency('usd')">USD - US Dollar</b-dropdown-item-button>
                    <b-dropdown-item-button @click="selectOutStandingCurrency('eur')">EUR - Euro</b-dropdown-item-button>
                    <b-dropdown-item-button @click="selectOutStandingCurrency('inr')" class="d-none">INR - Indian Rupee</b-dropdown-item-button>
                  </b-dropdown>
                  <b-button pill class="mt-2 px-4 px-md-5 px-lg-5 px-xl-4 py-2 font-weight-bold btn-primary-dark d-none"><b-icon icon="reply-fill" class="mr-2"></b-icon>{{ $t('Payment') }}</b-button>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col xl="6" lg="6" sm="12" md="12" class="mb-4">
            <b-card :title="$t('Address')" :sub-title="$t('Default billing address')">
              <div v-if="!isLoadingInvoiceAddress">
                <div v-if="invoiceAddress" class="flex-justify-content-space-between">
                  <div>
                    <b-card-text class="font-weight-bold">{{ invoiceAddress.first_name }} {{ invoiceAddress.last_name }}</b-card-text>
                    <b-card-text>{{ invoiceAddress.company_name }} {{ invoiceAddress.address }} {{ invoiceAddress.region_name }} {{ invoiceAddress.country_name }} {{ invoiceAddress.post_code }}</b-card-text>
                  </div>
                  <div class="columns">
                    <b-button variant="outline-dark" v-b-modal.modal-edit-bill-address>{{ $t('Edit') }}</b-button>
                  </div>
                </div>
                <div v-else>
                  <b-button variant="outline-dark" v-b-modal.modal-edit-bill-address>{{ $t('Add invoice address') }}</b-button>
                </div>
              </div>
              <div v-else class="justify-content-center d-flex">
                <b-spinner label="Loading"></b-spinner>
              </div>
            </b-card>
          </b-col>
        </div>
        <div class="mt-4">
          <h3 class="section-header-title text-primary-dark d-flex">{{ $t('Billing History') }}</h3>
          <b-row class="float-right">
            <div class="col-12 pb-1">
              <b-form-select v-model="paymentStatusSelected" @change="filterInvoice" :options="paymentStatusOptions"></b-form-select>
            </div>
          </b-row>
          <div class="table-responsive" style="min-height: 60px;">
            <b-table
              striped
              hover
              :items="billingItems"
              :per-page="perPage"
              :fields="fields">
              <template #cell(status)="row" class="px-0">
                <b-button v-if="row.item.status == 2 " size="sm" @click="receipt(row.item, row.index, $event.target)" class="mr-1" variant="outline-success">
                  {{ $t('Receipt') }}
                </b-button>
                <b-button v-else-if="row.item.status == 1 " size="sm" @click="payment(row.item)" class="mr-1 btn-danger-light" variant="danger">
                  {{ $t('Payment') }}
                </b-button>
                <b-button v-else size="sm" class="mr-1 btn-warning-light" variant="warning">
                  {{ row.item.status_name }}
                </b-button>
              </template>
              <template #cell(sub_total)="row">
               <p>{{ getAmountByCurrency(row.item, 'sub_total', row.item.paid_currency) | numeral('0,0.00') }}</p>
              </template>
              <template #cell(discount_amount)="row">
               <p>{{ getAmountByCurrency(row.item, 'discount_amount', row.item.paid_currency) | numeral('0,0.00') }}</p>
              </template>
              <template #cell(total)="row">
               <p>{{ getAmountByCurrency(row.item, 'total', row.item.paid_currency) | numeral('0,0.00') }}</p>
              </template>
              <template #cell(vat)="row">
               <p>{{ getAmountByCurrency(row.item, 'vat', row.item.paid_currency) | numeral('0,0.00') }}</p>
              </template>
              <template #cell(grand_total)="row">
               <p>{{ getAmountByCurrency(row.item, 'grand_total', row.item.paid_currency) | numeral('0,0.00') }} <span class="text-uppercase">{{ row.item.paid_currency }}</span></p>
              </template>
            </b-table>
          </div>
          <div class="mt-3" v-if="rows > 0">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" class="justify-content-center"></b-pagination>
          </div>
        </div>
        <b-modal id="modal-edit-bill-address" hide-footer size="lg" :title="$t('Billing Address')">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form ref="form" @submit.stop.prevent="handleSubmit(handleSubmitBillingAddress)">
            <b-row class="mt-3">
              <b-col xl="6" lg="6" sm="12" md="12">
                <validation-provider
                  :name="$t('First name')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group
                  :label="$t('First name') +'*'"
                  label-for="first_name"
                >
                  <b-form-input v-model="formInvoiceAddress.first_name" id="first_name" type="text" :placeholder="$t('First name')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col xl="6" lg="6" sm="12" md="12">
                <validation-provider
                  :name="$t('Last name')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group
                  :label="$t('Last name') +'*'"
                  label-for="lasat_name"
                >
                  <b-form-input v-model="formInvoiceAddress.last_name" type="text" :placeholder="$t('Last name')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="12" lg="12" sm="12" md="12">
                <b-form-group
                  id="organization"
                  description=""
                  v-model="formInvoiceAddress.organization"
                  :label="$t('Organization/Company (option)')"
                  label-for="organization"
                >
                  <b-form-input id="organization" v-model="formInvoiceAddress.company_name" trim></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="6" lg="6" sm="12" md="12" class="mb-4">
                <validation-provider
                  :name="$t('Phone number')"
                  rules="required|numeric"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group
                  :label="$t('Phone number') +'*'"
                  label-for="phone_number"
                >
                  <b-form-input v-model="formInvoiceAddress.phone_number" id="phone_number" type="text" :placeholder="$t('Phone number')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col xl="6" lg="6" sm="12" md="12" class="mb-4">
                <validation-provider
                  :name="$t('Email')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group
                  :label="$t('Email') +'*'"
                  label-for="email"
                >
                  <b-form-input v-model="formInvoiceAddress.email" type="text" :placeholder="$t('Email')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="12" lg="12" sm="12" md="12">
                <validation-provider
                  :name="$t('Address')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group
                  id="address"
                  :label="$t('Address')  +'*'"
                  label-for="address"
                >
                  <b-form-input id="address-line-1" v-model="formInvoiceAddress.address" :placeholder="$t('Address')" trim></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="6" lg="6" sm="12" md="12">
                <validation-provider
                  :name="$t('Country')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group
                description=""
                :label="$t('Country')  +'*'"
                label-for="country"
                >
                  <b-form-select v-model="formInvoiceAddress.country" :options="countries" @change="getRegionByCountry">
                    <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col  xl="6" lg="6" sm="12" md="12" class="mb-3">
                <validation-provider
                  :name="$t('State/province')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group
                  id="province"
                  description=""
                  :label="$t('State/province')  +'*'"
                  label-for="province"
                >
                  <b-form-select v-model="formInvoiceAddress.region" :options="regions">
                    <template #first>
                    <b-form-select-option :value="null" disabled>{{ $t('Click Here') }}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="6" lg="6" sm="12" md="12" class="mb-4">
                <validation-provider
                  :name="$t('Post code')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group
                  :label="$t('Post code') +'*'"
                  label-for="post_code"
                >
                  <b-form-input v-model="formInvoiceAddress.post_code" id="post_code" type="text" :placeholder="$t('Post code')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-4 justify-content-center d-flex">
                <b-button  variant="danger" @click="$bvModal.hide('modal-edit-bill-address')">
                  {{ $t('Cancel') }}
                </b-button>
                <b-button type="submit" :disabled="isLoadingInvoiceAddress" variant="primary" class="ml-2">
                  <small><b-spinner v-if="isLoadingInvoiceAddress" small label="Loading"></b-spinner></small>
                  {{ $t('Confirm') }}
                </b-button>
              </b-col>
            </b-row>
          </form>
          </ValidationObserver>
        </b-modal>
      </div>
    </b-container>
  </div>
</template>

<script>
import Api from '../../api/api'

export default {
  name: 'Billing',
  title: ' | Billing',
  data () {
    return {
      loading: true,
      billingAddress: {
        first_name: null,
        last_name: null
      },
      rows: 0,
      currentPage: 1,
      perPage: 25,
      fields: [{ key: 'created_at', label: this.$t('Created at') }, { key: 'ref_invoice', label: this.$t('Ref Inc') }, { key: 'sub_total', label: this.$t('Sub total') }, { key: 'discount_amount', label: this.$t('Discount') }, { key: 'total', label: this.$t('Total') }, { key: 'vat', label: this.$t('Vat') }, { key: 'grand_total', label: this.$t('Grand total') }, { key: 'phone_number', label: this.$t('Phone number') }, { key: 'status', label: this.$t('Status') }],
      billingItems: [],
      paymentStatusSelected: null,
      paymentStatusOptions: [
        { value: null, text: this.$t('Select payment status') },
        { value: '1', text: this.$t('Waiting for payment') },
        { value: '2', text: this.$t('Paid') },
        { value: '3', text: this.$t('Cancel') },
        { value: '4', text: this.$t('Refund') }
      ],
      outStandingAmount: {
        amount: 0.00,
        currency: 'usd'
      },
      selectedOutStandingCurrency: 'USD',
      isLoadingOutstanding: true,
      formInvoiceAddress: {
        id: null,
        user: null,
        first_name: null,
        last_name: null,
        email: null,
        address: null,
        post_code: null,
        company_name: null,
        region: null,
        country: null
      },
      invoiceAddress: null,
      isLoadingInvoiceAddress: true,
      form: null,
      countries: [],
      regions: []
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
    this.getInvoice()
    this.getOutStandingAmount(this.outStandingAmount.currency)
    this.getCountry()
    this.formInvoiceAddress.user = this.userProfile.id
    this.getInvoiceAddress()
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    payment (item) {
      this.$router.push({ name: 'billingCheckout', params: { invoiceId: item.id } })
    },
    receipt (item, index, button) {
      this.$router.push({ name: 'billingInvoiceDetail', params: { invoiceId: item.id } })
    },
    handleSubmitBillingAddress () {
      this.$refs.form.validate().then(result => {
        if (result) {
          if (this.formInvoiceAddress.id) {
            this.updateInvoiceAddress()
          } else {
            this.addInvoiceAddress()
          }
        }
      })
    },
    filterInvoice () {
      this.getInvoice()
    },
    getInvoice () {
      Api.invoice({ user: this.userProfile.id, limit: 1000, status: this.paymentStatusSelected }).then((response) => {
        this.billingItems = response.data.results
        this.rows = response.data.count
      })
        .catch(() => {
          this.rows = 0
          this.billingItems = []
        })
    },
    getOutStandingAmount (currency) {
      Api.outStanding({ currency: currency }).then((response) => {
        this.outStandingAmount = response.data
        this.isLoadingOutstanding = false
      })
        .catch(() => {
          this.isLoadingOutstanding = false
        })
    },
    selectOutStandingCurrency (currency) {
      this.selectedOutStandingCurrency = currency.toUpperCase()
      this.getOutStandingAmount(currency)
    },
    getInvoiceAddress () {
      Api.getInvoiceAddress({ user: this.userProfile.id }).then((response) => {
        if (response.data.results.length > 0) {
          this.invoiceAddress = response.data.results[0]
          this.formInvoiceAddress = { ...this.formInvoiceAddress, ...response.data.results[0] }
          if (this.formInvoiceAddress.country) {
            this.getRegion(this.formInvoiceAddress.country)
          }
        } else {
          this.invoiceAddress = null
        }
        this.isLoadingInvoiceAddress = false
      })
        .catch(() => {
          this.isLoadingInvoiceAddress = false
        })
    },
    addInvoiceAddress () {
      this.isLoadingInvoiceAddress = true
      Api.addInvoiceAddress(this.formInvoiceAddress).then((response) => {
        this.isLoadingInvoiceAddress = false
        this.invoiceAddress = response.data
        this.formInvoiceAddress = { ...this.formInvoiceAddress, ...response.data }
        this.$notify({
          group: 'success',
          title: this.$t('Invoice address'),
          text: this.$t('Save invoice address successful')
        })
        this.$bvModal.hide('modal-edit-bill-address')
      })
        .catch(() => {
          this.isLoadingInvoiceAddress = false
          this.$notify({
            group: 'error',
            title: this.$t('Invoice address'),
            text: this.$t('Save invoice address unsuccessful')
          })
        })
    },
    updateInvoiceAddress () {
      this.isLoadingInvoiceAddress = true
      Api.updateInvoiceAddress(this.formInvoiceAddress).then((response) => {
        this.isLoadingInvoiceAddress = false
        this.invoiceAddress = response.data
        this.formInvoiceAddress = { ...this.formInvoiceAddress, ...response.data }
        this.$notify({
          group: 'success',
          title: this.$t('Invoice address'),
          text: this.$t('Save invoice address successful')
        })
        this.$bvModal.hide('modal-edit-bill-address')
      })
        .catch(() => {
          this.isLoadingInvoiceAddress = false
          this.$notify({
            group: 'error',
            title: this.$t('Invoice address'),
            text: this.$t('Save invoice address unsuccessful')
          })
        })
    },
    getRegionByCountry () {
      this.getRegion(this.formInvoiceAddress.country)
    },
    getCountry () {
      Api.country({ limit: 300 }).then((response) => {
        if (response.data) {
          this.countries = []
          response.data.results.forEach(country => {
            const url = country.url.split('/')
            this.countries.push({ value: url[url.length - 2], text: country.name })
          })
        }
      })
    },
    getRegion (countryId) {
      Api.region({ limit: 300, country: countryId }).then((response) => {
        if (response.data) {
          this.regions = []
          response.data.results.forEach(region => {
            const url = region.url.split('/')
            this.regions.push({ value: url[url.length - 2], text: region.name })
          })
        }
      })
    },
    getAmountByCurrency (item, field, currency) {
      const fieldName = field + '_' + currency
      return item[fieldName]
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
